
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































































































































.info {
  margin-top: 3rem;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;
  }
}

.text-small {
  font-size: small;
}

.info__type {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 2.4rem;

  img {
    margin-right: 1rem;
  }

  @include mq(m) {
    font-size: 3.6rem;
  }
}

.info__ids {
  color: $c-gray-dark;
}

.meter--big {
  ::v-deep .input {
    padding: 0 1.3rem;
  }
}

.values {
  @extend %shadow;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0 3rem;
  padding: 3.5rem col(1);
  border-radius: 20px;

  @include mq($until: xs) {
    box-shadow: none;
  }

  @include mq(m) {
    margin: 7rem 0 6rem;
    padding: 8.5rem col(1, 14);
    border-radius: 40px;
  }
}

.values__items__item {
  &:not(:last-child) {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, xxl: 30px));
  }
}

.value {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(m) {
    margin-bottom: 8rem;
  }
}

.value__headline {
  display: flex;
  line-height: 1;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.5rem;
    fill: $c-orange;
  }
}

.value__meter {
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav__back {
  display: flex;
  align-items: center;
  color: $c-gray-light;
  text-decoration: none;

  svg {
    width: 1.2rem;
    height: 1rem;
    margin-right: 0.5rem;
    fill: $c-gray-light;
    transform: rotate(90deg);
    transition: transform 0.2s;
  }

  &:hover {
    svg {
      transform: translateX(-5px) rotate(90deg);
    }
  }
}

.nav__submit {
  @include loading($c-orange);
}
.header__icon {
  width: 3.8rem;
  height: 3.8rem;
  margin: 0 0.5rem 0 -1rem;

  &.elec {
    fill: $c-main-secondary;
  }

  &.gaz {
    fill: $c-orange;
  }

  &.propane {
    fill: $c-blue;
  }

  @include mq(s) {
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 0.5rem 0 -1.5rem;
  }
}
