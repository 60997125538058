
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























































































































































.container {
  margin-top: 3rem;
  margin-bottom: 9rem;

  @include mq(m) {
    margin-top: 10rem;
    margin-bottom: 21rem;
  }
}

.content {
  position: relative;
}

.intro__headline {
  display: block;
  margin-bottom: 1rem;
}

.intro__title {
  @extend %fw-bold;

  margin-bottom: 3rem;

  @include mq(m) {
    margin-bottom: 4rem;
  }
}

.intro__address {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;

  .icon {
    margin-right: 1rem;
    fill: $c-orange;
  }

  @include mq(m) {
    margin-bottom: 6rem;
  }
}

.intro__message {
  @include fluid-type(18, 24);

  display: flex;
  align-items: center;
  color: $c-gray-darker;

  img {
    margin-right: 1rem;
  }

  &.is-submitted {
    color: $c-green;
  }
}

.intro__message__text {
  line-height: 1.1;
}

.recap__header {
  .icon {
    &.elec {
      fill: $c-yellow;
    }

    &.gaz,
    &.propane {
      fill: $c-orange;
    }
  }
}

.recap__header__content {
  flex: 1;

  @include mq(s) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.recap__header__content__ean {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, xxl: 15));

  margin-top: 2rem;
  color: $c-gray-dark;

  @include mq(s) {
    margin-top: 0;
  }
}

.recap__header__content__meter {
  @extend %fw-bold;
  @include fluid-type(18, 24);

  .headline {
    @extend %fw-normal;

    color: $c-green;
  }
}

.recap__registers {
  &:not(:last-child) {
    border-bottom: 1px solid $c-gray-light;
  }
}

.recap__registers__item {
  .headline {
    color: $c-gray-dark;
    letter-spacing: 0.5px;
  }
}

.recap__registers__item__list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    break-inside: avoid-column;
  }

  @include mq(xxs) {
    columns: 2;
  }
}

.recap__registers__item__title {
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xxl: 18px));

  display: block;
}

.recap__registers__item__value {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 24px));
}

.aside {
  @include mq(l) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.number {
  margin-left: 3rem;
}

.eans {
  min-height: 30rem;

  &.is-loading {
    background: transparent url('/assets/images/loader.svg') no-repeat center;
  }
}
.box {
  padding-left: 45px;
}
.meter-wizard {
  position: relative;
  max-width: 100%;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 24px, m: 48px));
      text-transform: none;
    }

    .wysiwyg ul,
    .wysiwyg ol {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    a.action--btn--link--icon--arrow {
      margin-bottom: 3rem;
    }

    .accordion {
      padding: 0 !important;
    }

    p.blockTitle {
      padding-left: 45px;
      font-size: 25px;
      font-weight: bolder;
      background-size: 30px auto;
      background-repeat: no-repeat;
      background-position: left;
    }

    div.faqAstuce {
      background-color: #fff4e0;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-green;
    }

    div.faqAstuce p.blockTitle {
      color: $c-green;
      background-image: url('../../assets/svg/icons/faq-astuce.svg');
    }

    div.faqAstuce a {
      color: $c-green;
      text-decoration: underline;
    }

    div.faqAstuce a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }

    div.faqInfo {
      background-color: #ebf6f2;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-green;
    }

    div.faqInfo p.blockTitle {
      color: $c-green;
      background-image: url('../../assets/svg/icons/faq-info.svg');
    }

    div.faqInfo a {
      color: $c-green;
      text-decoration: underline;
    }

    div.faqInfo a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }

    div.faqAttention {
      background-color: #fce9e2;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-red;
    }

    div.faqAttention p.blockTitle {
      color: $c-red;
      background-image: url('../../assets/svg/icons/faq-attention.svg');
    }

    div.faqAttention a {
      color: $c-red;
      text-decoration: underline;
    }

    div.faqAttention a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }
  }
}
