
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.meter {
  overflow: hidden;

  &.is-submitted {
    pointer-events: none;
    opacity: 0.8;
  }
}

.container {
  position: relative;
  display: inline-flex;
  line-height: 1;
}

.input {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: calc(100% + 5px);
  // width: 100%;
  height: 100%;
  color: $c-white;
  font-family: SpaceMono, monospace;
  font-size: 2.4rem;
  text-transform: uppercase;
  background: transparent;
  border: 0;

  padding: 0 0 0 0.3em;
  letter-spacing: 0.55em;

  @media screen and (max-width: 720px) {
    font-size: 2.1rem;
    padding: 0 0.4rem;
    letter-spacing: 0.28em;
  }

  &::-webkit-search-cancel-button {
    // stylelint-disable-next-line
    -webkit-appearance: none;
  }

  [class*='meter--'][class*='--light'] & {
    // padding: 0;
    color: $c-black;
  }

  &::-ms-clear {
    display: none;
  }

  @include mq(m) {
    [class*='meter--'][class*='--big'] & {
      font-size: 4.4rem;
      padding: 0 0 0 0.4em;
    }
  }
}

.cases {
  display: flex;
  overflow: hidden;
  border-radius: 5px;

  [class*='meter--'][class*='--light'] & {
    border-radius: none;
  }
}

.case {
  position: relative;
  text-align: center;
  background: $c-black;

  span {
    position: relative;
    display: block;
    width: 2.65rem;
    height: 100%;
    min-height: 5rem;

    @media screen and (max-width: 720px) {
      width: 1.75rem;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 10px;
      left: 15%;
      width: 70%;
      height: 1px;
      background: $c-gray-lighter;
    }
  }

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }

  &.has-comma {
    z-index: 1;

    &::after {
      @extend %fw-bold;

      content: ',';
      position: absolute;
      right: -2px;
      bottom: 7px;
      color: $c-white;
      line-height: 1;
    }

    [class*='meter--'][class*='--light'] & {
      color: $c-black;
    }
  }

  &.is-decimal {
    background: $c-main-primary;

    [class*='meter--'][class*='--light'] & {
      background: transparent;
    }
  }

  [class*='meter--'][class*='--light'] & {
    background: transparent;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @include mq(m) {
    [class*='meter--'][class*='--big'] & {
      span {
        width: 4.8rem;
        min-height: 8rem;
      }
    }
  }
}
